<template>
  <div class="navbar">
    <div class="nav-top">
      <div class="logo">{{ isAgent ? '代办处订货系统' : '订货系统' }}</div>
      <div class="welcome">
        <el-link class="periods" :underline="false" @click="handleSettlementPeriods">2024年结算周期表</el-link>
        <div class="cur-period">
          <span v-show="curWeek.chinaWWeek">当前期数{{ curWeek.chinaWWeek }}({{ curWeek.start }}~{{ curWeek.end }})</span>
        </div>
        <div class="name">{{ username }}</div>
        <div class="code">{{ userCode }}</div>
        <el-link class="logout" :underline="false" @click="handleLogout">[退出]</el-link>
      </div>
      <div class="lang">
        <el-dropdown @command="handleSetLanguage">
        <span class="el-dropdown-link">
          {{ languageName }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="lang in languages"
            :key="lang.code"
            :disabled="lang.code===language"
            :command="lang.code"
          >
            {{ lang.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      </div>
    </div>
    <div class="nav-menu">
      <el-menu
        class="menu"
        text-color="#B3640d"
        active-text-color="#B3640d"
        mode="horizontal"
      >
        <template v-for="(item,index) in permission_routes">
          <template v-if="!item.hidden">
            <el-menu-item :key="index" v-if="item.children && item.children.length===1" :index="item.path" @click="handleLinkto(item, item.children[0])">
              {{ item.children[0].meta.title }}
            </el-menu-item>
            <el-submenu :key="index" v-if="item.children && item.children.length>1" :index="item.path">
              <template slot="title">{{ item.meta.title }}</template>
              <el-menu-item v-for="subItem in item.children.filter(ele => !ele.hidden)" :key="subItem.path" @click="handleLinkto(item, subItem)">
                {{ subItem.meta.title }}
              </el-menu-item>
            </el-submenu>
          </template>
        </template>
      </el-menu>
    </div>
    <el-dialog title="2024年结算周期表" :visible.sync="dialogTableVisible" center>
      <settlement-periods />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { fetchCurrentWeek } from '@/api/home'
import SettlementPeriods from './SettlementPeriods'
import { getLanguage } from '@/lang/index'

export default {
  name: 'NavBar',
  components: { SettlementPeriods },
  data() {
    return {
      activeIndex: '',
      curWeek: {
        chinaWWeek: '',
        start: '',
        end: ''
      },
      dialogTableVisible: false,
      languages: [
        { code: 'zh_CN', name: 'Chinese 中文' },
        { code: 'en', name: 'English 英文' },
        { code: 'ms', name: 'Malaysia 马来文' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'username',
      'isAgent',
      'permission_routes'
    ]),
    languageName() {
      const lang = this.languages.find(ele => ele.code === this.$store.getters.language)
      if (lang) {
        return lang.name
      }
      return this.languages[0].name
    }
  },
  mounted() {
    this.getCurrentWeek()
  },
  methods: {
    getCurrentWeek() {
      fetchCurrentWeek().then(res => {
        this.curWeek = res.data
      })
    },
    async handleLogout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('app/setLanguage', lang)
      getLanguage()
    },
    handleLinkto(parent, item) {
      let url = parent.path === '/' ? (parent.path + item.path) : (parent.path + '/' + item.path)
      this.$router.push(url).catch(() => {})
    },
    handleSettlementPeriods() {
      this.dialogTableVisible = true
    }
  }
}
</script>

<style>

</style>
