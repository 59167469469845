<template>
  <div class="settlement-table">
    <div v-for="(item, index) in list" class="month" :key="item.month">
      <div class="title">{{ item.month }}</div>
      <el-table :data="item.periods" :table-key="index" size="mini" style="width: 100%" stripe>
        <el-table-column prop="week" label="中国期次" width="80" />
        <el-table-column prop="week" label="起止日期" header-align="center">
          <template slot-scope="{row}">
            <span>{{ row.start }} ~ {{ row.end }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettlementPeriods',
  data() {
    return {
      year: 2024,
      list: [
        { month: '1月',
          periods: [
            { week: '202401', start: '2024-01-03', end: '2024-01-09' },
            { week: '202402', start: '2024-01-10', end: '2024-01-16' },
            { week: '202403', start: '2024-01-17', end: '2024-01-23' },
            { week: '202404', start: '2024-01-24', end: '2024-01-30' },
            { week: '202405', start: '2024-01-31', end: '2024-02-06' }
          ]
        },
        { month: '2月',
          periods: [
            { week: '202406', start: '2024-02-07', end: '2024-02-13' },
            { week: '202407', start: '2024-02-14', end: '2024-02-20' },
            { week: '202408', start: '2024-02-21', end: '2024-02-27' },
            { week: '202409', start: '2024-02-28', end: '2024-03-05' }
          ]
        },
        { month: '3月',
          periods: [
            { week: '202410', start: '2024-03-06', end: '2024-03-12' },
            { week: '202411', start: '2024-03-13', end: '2024-03-19' },
            { week: '202412', start: '2024-03-20', end: '2024-03-26' },
            { week: '202413', start: '2024-03-27', end: '2024-04-02' }
          ]
        },
        { month: '4月',
          periods: [
            { week: '202414', start: '2024-04-03', end: '2024-04-09' },
            { week: '202415', start: '2024-04-10', end: '2024-04-16' },
            { week: '202416', start: '2024-04-17', end: '2024-04-23' },
            { week: '202417', start: '2024-04-24', end: '2024-04-30' }
          ]
        },
        { month: '5月',
          periods: [
            { week: '202418', start: '2024-05-01', end: '2024-05-07' },
            { week: '202419', start: '2024-05-08', end: '2024-05-14' },
            { week: '202420', start: '2024-05-15', end: '2024-05-21' },
            { week: '202421', start: '2024-05-22', end: '2024-05-28' },
            { week: '202422', start: '2024-05-29', end: '2024-06-04' }
          ]
        },
        { month: '6月',
          periods: [
            { week: '202423', start: '2024-06-05', end: '2024-06-11' },
            { week: '202424', start: '2024-06-12', end: '2024-06-18' },
            { week: '202425', start: '2024-06-19', end: '2024-06-25' },
            { week: '202426', start: '2024-06-26', end: '2024-07-02' }
          ]
        },
        { month: '7月',
          periods: [
            { week: '202427', start: '2024-07-03', end: '2024-07-09' },
            { week: '202428', start: '2024-07-10', end: '2024-07-16' },
            { week: '202429', start: '2024-07-17', end: '2024-07-23' },
            { week: '202430', start: '2024-07-24', end: '2024-07-30' },
            { week: '202431', start: '2024-07-31', end: '2024-08-06' }
          ]
        },
        { month: '8月',
          periods: [
            { week: '202432', start: '2024-08-07', end: '2024-08-13' },
            { week: '202433', start: '2024-08-14', end: '2024-08-20' },
            { week: '202434', start: '2024-08-21', end: '2024-08-27' },
            { week: '202435', start: '2024-08-28', end: '2024-09-03' }
          ]
        },
        { month: '9月',
          periods: [
            { week: '202436', start: '2024-09-04', end: '2024-09-10' },
            { week: '202437', start: '2024-09-11', end: '2024-09-17' },
            { week: '202438', start: '2024-09-18', end: '2024-09-24' },
            { week: '202439', start: '2024-09-25', end: '2024-10-01' }
          ]
        },
        { month: '10月',
          periods: [
            { week: '202440', start: '2024-10-02', end: '2024-10-08' },
            { week: '202441', start: '2024-10-09', end: '2024-10-15' },
            { week: '202442', start: '2024-10-16', end: '2024-10-22' },
            { week: '202443', start: '2024-10-23', end: '2024-10-29' },
            { week: '202444', start: '2024-10-30', end: '2024-11-05' }
          ]
        },
        { month: '11月',
          periods: [
            { week: '202445', start: '2024-11-06', end: '2024-11-12' },
            { week: '202446', start: '2024-11-13', end: '2024-11-19' },
            { week: '202447', start: '2024-11-20', end: '2024-11-26' },
            { week: '202448', start: '2024-11-27', end: '2024-12-03' }
          ]
        },
        { month: '12月',
          periods: [
            { week: '202449', start: '2024-12-04', end: '2024-12-10' },
            { week: '202450', start: '2024-12-11', end: '2024-12-17' },
            { week: '202451', start: '2024-12-18', end: '2024-12-24' },
            { week: '202452', start: '2024-12-25', end: '2024-12-31' }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.settlement-table {
  display: flex;
  flex-wrap: wrap;
  .month {
    width: calc(33% - 20px);
    padding: 0 10px;
    .title {
      height: 40px;
      line-height: 40px;
      background-color: #3e4f6a;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>
